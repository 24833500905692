.widget-heading{
	margin-bottom: 0.8125rem;
	flex-flow: wrap;
	a{
		font-size: 1.125rem;
		font-weight: 400;
	}
}
.widget-courses{
	h4{
		font-size: 1.125rem;
		font-weight: 500;
	}
	
	.schedule-icon{
		height: 4rem;
	    width: 4rem;
	    display: block;
	    text-align: center;
	    line-height: 4rem;
	    border-radius: 50%;
	    color: #374557;
	    font-size: 1.125rem;
	    font-weight: 600;
		margin-right: 1.25rem;
		@include respond ('phone'){
			margin-bottom: 0.625rem;
			height: 3.125rem;
			width: 3.125rem;
			line-height: 3.125rem;
			font-size: 0.875rem;
			
		}
	}
	i{
		transform: scale(1.2);
	}
	
	&.style-1{
		h4{
			font-size:1.5rem;
			font-weight:700;
			margin-bottom:0;
			@include respond ('phone-land'){
				font-size:1.125rem;
			}
		}
		img{
			width:3.125rem;
		}
		span{
			font-size: 1.125rem;
			@include respond ('phone-land'){
				font-size: 0.875rem;
			}
		}
		@include custommq($max: 106.25rem) {
			img{
				width:3.125rem;
			}
		}
	}
	&.style-2{
		h4{
			color:$white;
		}
		span{
			color:$white;
		}
		&:hover{
			.peity{
				transform: scale(1.3);
				@include transitionMedium;
			}
		}
	}
}
.courses-bx{
	.dlab-media{
		img{
			width:100%;
			height: 15rem;
			border-radius: $radius;
			object-fit:cover;
		}
	}
	.dlab-info{
		.dlab-title{
			margin: 1.25rem 0;
			h4{
				margin-bottom: 0;
				a{
					color:$black;
				}
			}
			p{
				span{
					padding: 0 0.375rem;
					align-items:center;
					svg{
						margin-left: 0.3125rem;
						margin-top: -0.3125rem;
					}
				}
			}
		}
		.content{
			font-size: 0.875rem;
			font-weight: 400;
			flex-flow: wrap;
		}
	}
}
.pagination-down{
	h4{
		font-size: 0.875rem;
		font-weight: 400;
		margin: 0;
		span{
			color: var(--primary);
		}
	}
	ul{
		display: flex;
		align-items:center;
		li{
			a{
				height:3.1875rem;
				width: 3.1875rem;
				border-radius: 0.5rem;
				text-align: center;
				background:$white;
				display: block;
			    line-height: 3.1875rem;
			    margin: 0 0.625rem;
			    font-size: 1.125rem;
			    color: var(--primary);
				@include respond ('phone'){
					height: 2.1875rem;
					width: 2.1875rem;
					font-size: 0.9375rem;
					line-height: 2.1875rem;
				}
			    &:last-child{
			    	margin-right: 0;
					
			    }
			    &.active,
				&:hover{
			    	background: var(--primary);
			    	color:$white;
					@include transitionMedium;
			    }
				
			}
			&:first-child{
				a{
					@include respond ('phone'){
						margin-left: 0;
					}
				}
				
			}
		}
	}
}

/* course details */
 
.course-dedails-bx{
	h2{
		font-size: 1.5rem;
		width: 70%;
		@include respond ('phone'){
			font-size: 1.125rem;
		}
	}
	.description{
		p{
			font-weight: 400;
			margin: 0;
			@include respond ('phone'){
				font-size: 0.875rem;
			}
		}
		.user-pic{
			display: flex;
			align-items:center;	
			img{
				height: 2.5rem;
				width: 2.5rem;
				border-radius: 0.5rem;
				margin-right: 0.9375rem;
			}
			span{
				font-size: 1rem;
				font-weight: 400;
				color:$headings-color;
			}
		}
	}
	

}
.star-orange{
	color: #FFD687;
}
.course-details-tab{
	padding: 1.5625rem 0;	
	h4{
		font-size: 1.125rem;
	}
	p{
		@include respond ('phone'){
			font-size: 0.875rem;
		}
	}
	.nav-tabs{
		border-bottom: 0.125rem solid $border;
		.nav-link{
			font-size: 1.125rem;
			font-weight: 600;
			background: transparent;
			padding: 1rem 0.875rem;
			border: 0;
			
			&:first-child{
				margin-left: 0;
			}
			&.active{
				color: var(--primary);
				border-bottom: 0.1875rem solid var(--primary)!important;
				padding-bottom: 0.875rem;
			}

		}
	}
	.about-content{
		margin-top: 1.875rem;
	}
	.user-pic2{
		padding-top: 2.375rem;
		p{
			margin:0;
			margin-top: 1rem;
			color:$headings-color;
		}
		img{
			height: 3.75rem;
			width: 3.75rem;
			border-radius: 0.5rem;
		}
		&:hover{
			h4{
				color:var(--primary);
				@include transitionMedium;
			}
		}
	}

	&.style-1{
		.nav-tabs{
			border-bottom: 0;
			.nav-link{
				border-bottom: 0.125rem solid $border;
				@include respond ('phone'){
					padding: 1rem 0.875rem;
					font-size:1rem;
				}
			}
		}
	}
	&.style-2{
		padding:0;
		&.tab-lg{
			.nav-tabs{
				.nav-link{
					font-size:1.125rem;
					@include respond ('phone'){
						font-size:.85rem;
						padding: 8px 8px;
					}
				}
			}
		}
		.nav-tabs{
			.nav-link{
				font-size:0.875rem;
				color:#A098AE;
				margin-bottom:0;
				border:0!important;
				flex:1;
				position:relative;
				padding: 0.75rem 1rem;
				&:after{
					content:"";
					position:absolute;
					left:50%;
					transform:translateX(-50%);
					bottom:-0.125rem;
					height:0.1875rem;
					width:0;
					background:var(--primary);
					border-radius:0.1875rem;
					@include transitionFast;
				}
				&.active{
					color:var(--primary);
					&:after{
						width:100%;
					}
				}
				@include custommq($max: 106.25rem) {
					padding: 1rem 1rem;
				}
				
				
			}
		}
	}
	&.style-3{
		.nav-tabs{
			.nav-link{
				padding: 1rem 1.125rem;
				position:relative;
				&:after{
					content:none;
					position:absolute;
					top:0.75rem;
					right:0;
					height:0.5rem;
					width:0.5rem;
					background:var(--primary);
					border-radius:3.125rem;
				}
				&.active{
					&:after{
						content:"";
					}
				}
			}
		}
	}
	.nav-tabs{
		.nav-link{
			&:hover{
				color:var(--rgba-primary-9);
			}
		}
	}
	
	
}
.raiting{
	margin: 1.25rem 0;	
	li{
		font-weight: 400;
		padding: 0 1.125rem;
	    padding-left: 1.125rem;
	    border-right: 0.125rem solid $border;
		@include respond ('phone'){
			padding: 0 0.8125rem;
		}
	    &:first-child{
			padding-left: 0;

		}
		&:last-child{
	  		border-right: 0;
			@include respond ('phone'){
				padding-left:6px;
			}
	  	}
	}

}
.video-img{
	.view-demo{
		position: relative;	
		overflow: hidden;
		img{
			width: 100%;
			border-radius: $radius;
			object-fit: cover;
			height: 25rem;
			@include respond ('phone'){
				height: 12rem;
			}
		}
		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width:100%;
			background:rgba(84, 124, 175, 0.25);
			z-index: 1;
			border-radius: $radius;
		}
		a{
			height: 4.375rem;
		    width: 4.375rem;
		    display: block;
		    position: relative;
		    z-index: 2;
		    margin: auto;
		    svg{
		    	path{
		    		fill{
		    			fill: #fff;
		    		}
		    	}
		    }
		}
		.play-button{
			position: absolute;
		    top: 50%;
		    left: 0;
		 	transform: translate(0, -3.125rem);
		    z-index: 2;
		    width: 100%;
			h4{
				font-size: 1.5rem;
				color: white;
				position: relative;
				z-index: 2;
				margin-top: 0.625rem;
			}
			@include respond ('phone'){
				svg{
					width:3.125rem;
				}
				h4{
					font-size: 1rem;
				}
			}
			
		}
	}
	&.style-1{
		.view-demo{
			img{
				height:35rem;
				@include respond ('phone'){
					height:13.125rem;
				}
			}
		}
	}
	
}
.course-prise{
	padding: 2.1875rem 0;
	h4{
		font-size: 1.5rem;
		align-items: center;
    	display: flex;
    	
		small{
			font-size: 1.125rem;
			font-weight: 400;
			color:$body-color;
			margin-left: 0.625rem;
		}
	}
	span{
		font-size: 0.875rem;
		font-weight: 500;
		&:hover{
			svg{
				transform: scale(1.2);
				@include transitionMedium;
			}
		}
	}
}
.course-learn{
	h4{
		font-size: 1.125rem;
		margin-bottom: 0.875rem;
	}
	ul{
		li{
			width: 50%;
		    display: flex;
		    float: left;
			color:$headings-color;
		    padding: 0.5625rem 0rem;
		    svg{
		    	margin-right: 0.625rem;
				margin-top: .1rem;
		    }
			@include respond ('phone'){
				width:100%;
			}
		}
	}
}

/* Course-details 2 */

.course-content{
	margin-bottom: 1.875rem;
	h3{
		font-size: 1.5rem;
		@include respond ('phone'){
			font-size:1.125rem;
		}
	}
	svg{
		margin: 0 0.625rem;
		cursor: pointer;
		&:hover{
			path{
				fill:var(--primary);
				@include transitionMedium;
			}
		}
	}
}
.progress-box{
	h4{
		font-size: 1.5rem;
	}
	.progress{
		height: 0.75rem;
		border-radius: 0.25rem;
		margin: 0.5625rem 0;
	}
}
.custome-accordion{
	.accordion-item{
		margin-bottom:1rem;
	}
	.accordion-header{
		padding: 0.3rem 1.75rem;
		border:0;
		@include respond ('phone'){
			padding: 0.3rem 1.05rem;
		}
		.acc-heading{
			font-size: 1.125rem;
			line-height: 1.5;
			font-weight: 600;
			color: #374557;
			margin-right:auto;
		}
		span{
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			color: #777777;
		}
		&:hover{
			.acc-heading{
				color:var(--primary);
			}
		}
	}	
	.accordion-button{
		background: white;
		border:0;	
		box-shadow: none;
		padding-left: 0;
		padding-right: 0;
		display: flex;
		align-items: center;
		&.collapsed{
			&:after{
				background-color: var(--rgba-primary-5);
			}
		}
		
		&:after{
			content:"";
			height: 2rem;
			width: 2rem;
			background-color:var(--primary);
			background-position: center;
			border-radius: 50%;
			margin-left: 0.625rem;
			display: block;
		    position: unset;
		    transform: none;
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
		}
		&:not(.collapsed)::after{
			content:"";
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
		    transform: rotate(180deg);
		}
	}
	.accordion-collapse{
		border: 0;
	}
	.acc-courses{
		background: #EEE;
	 	padding: 1.1rem 1.25rem;
	    border-radius: 0.625rem;
	    cursor: pointer;
		
		@include respond ('phone'){
			padding: 0.625rem 1.25rem;
		}
	    &:not(:first-child):not(:last-child){
	    	margin: 1.25rem 0;
	    }
	    &.active{
	    	background: var(--primary);
	    	h4,span{
	    		color:white;

	    	}
	  
	    }
	    h4,span{
	    	font-size: 1rem;
	    	font-weight: 400;
	    }
		.acc-icon{
			height: 2rem;
		    width: 2rem;
		    background: #ffffff;
		    text-align: center;
		    line-height: 2rem;
		    border-radius: 50%;
		    margin-right: 0.9375rem;
		} 
		&:hover:not(.active){
			background:var(--rgba-primary-1);
			@include transitionMedium;
			.acc-icon{
				background:var(--primary);
				@include transitionMedium;
				svg{
					path{
						fill:$white;
						@include transitionMedium;
					}
				}
			}
		}	

	}
	&.style-1{
		.accordion-header{
			padding:0;
			.acc-header{
				h4{
					font-size:1rem;
				}
			}
			.accordion-button{
				padding:0;
			}
		}
		.acc-courses{
			background:transparent;
			border-bottom:0.0625rem solid $border;
			border-radius: 0;
			padding: 1rem 0;
			margin: 0!important;
			h4{
				color:$black;
			}
		}
		.acc-icon{
			background: var(--primary);
		}
	}
}	

.nt-unseen{
	position:relative;
	&:before{
		content:"";
		position:absolute;
		top: 3px;
		right: 5px;
		height:8px;
		width:8px;
		background-color:#FC6B57;
		border-radius:8px;
	}
}

.students1{
	display:flex;
	align-items:center;
	@include respond ('laptop'){
		display:block;
	}
	@include respond ('tab-land'){
		display:flex;
	}
}
.course-details-tab.style-2 .nav-tabs.tab-auto{
	.nav-link{
		flex:unset;
	}
}
.profile-widget{
	&:hover{
		transform: translateY(-10px);
	}
	
}
.all-crs-wid{
	&:hover{
		.courses-bx{
			h4{
				a{
					color:var(--primary);
					@include transitionMedium;
				}
			}
		}
	}
}
.course-slider{
	.swiper-slide{
		.card{
			&:hover{
				box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
				.widget-courses{
					img{
						-webkit-animation-name: bounce;
						opacity:1;
						animation-duration: 1.5s;
					}
					
				}
			}
		}
	}
}