.chat-sidebar{
	height: calc(100vh - 11.25rem);
	@include respond ('tab-land'){
		height: calc(100vh - 9.25rem);
	}
	@include respond ('phone-land'){
		box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
	}
}
.message-body{
	min-height:auto!important;
}	
.message-box{
	border-bottom:0.0625rem solid #DBDBDB;
	background: white;
    padding-bottom: 1.25rem;
	img{
		height:5rem;
		width:5rem;
		border-radius:$radius;
	}
	h4{
		font-weight:700;
	}
	span{
		font-size:1.125rem;
	}
	&.style-1{
		padding:1rem;
		border-radius:$radius;
		border:0;
		h4{
			margin-bottom:.25rem;
			@include respond ('phone'){
				margin-bottom:0;
			}
		}
		span{
			font-size:14px;
		}
		img{
			height:4rem;
			width:4rem;
			@include respond ('phone'){
				height: 3rem;
				width: 3rem;
			}
		}
	}
	@include respond ('phone'){
		img{
			height: 3rem;
			width: 3rem;
		}
		h3{
			font-size: 1rem;
		}
		span{
			font-size: .85rem;
		}
	}
}
.chat-people{
	border-bottom:0.0625rem solid #DBDBDB;
	padding:1.25rem 0;
}
.chat-tabs{
	& > h4{
		font-size: 1rem;
		padding: 1.5625rem 0;
		margin: 0;
		padding-bottom: 0.6875rem;
	}
	.chat-bx{
		padding: 1rem 0;
		position:relative;
		border-bottom:0.0625rem solid #DBDBDB;
		z-index:1;
		display:flex;
		cursor:pointer;
		margin: 10px 0;
		align-items:center;
		.right-info{
			width: 4.375rem;
			text-align: right;
			p{
				font-size:.8rem;
				margin-top: 0;
				margin-bottom: .5rem;	
			}
			@include respond ('laptop'){
				width: 4rem;
			}
		}
		.mid-info{
			width: calc(100% - 9rem);
			h4{
				font-size: 1rem;
				margin-bottom: .25rem;
			}
			span{
				white-space:nowrap;
				text-overflow:ellipsis;
				display:block;
				overflow:hidden;
			}
			@include respond ('laptop'){
				width: calc(100% - 8rem);
			}
		}
		
		.chat-img{
			position: relative;
			img{
				height: 3.75rem;
				width: 3.75rem;
				border-radius:$radius;
				margin-right: 1rem;
				@include respond ('laptop'){
					height: 3rem;
					width: 3rem;
				}
			}
			.active{
				height:1rem;
				width:1rem;
				border:0.125rem solid $white;
				border-radius:0.25rem;
				background:$secondary;
				position: absolute;
				bottom: -0.0625rem;
				right: 1rem;
			}
		
		}
		.badge {
			border-radius:0.375rem;
		}
		&:last-child{
			border-bottom:0;
		}
		@include respond ('laptop'){
			padding: .75rem 0;
		}
		&:after{
			content:none;
			position:absolute;
			top:0;
			left:0;
			height: calc(100% + 4px);
			width: calc(100% + 30px);
			z-index:-1;
			background-color:#f0f0f0;
			border-radius:$radius;
			margin: -2px -15px;
		}
		&:hover,
		&.active{
			&:after{
				content:"";
			}
			.mid-info{
				h4{
					color:var(--primary);
				}
			}
		}
	}
}
.chat-box-area{
	height: calc(100vh - 21.8rem);
	
	.message-received {
		width: 62%;
		text-align: left;	
		p{
			background:$white;
			text-align: left;
			border-radius:$radius;
			padding: 0.625rem 1.25rem;
			color: #000;
		}
		@include respond('phone'){
			width:100%;	
		}
	}
	.message-sent{
		width: 62%;
		text-align: right;
		max-width: 83%;
		@include respond ('tab-port'){
			width: 84%;
		}
		span{
			display:block;
		}
	}
	.message-sent p{
		background: var(--secondary);
		text-align: left;
		border-radius:$radius;
		padding: 0.625rem 1.25rem;
		color: #fff;
		display:inline-block;
	}
	&.style-1{
		height: 21.5rem;
		.message-received {
			p{
				background:#eeee;
				font-size:0.875rem;
			}
		}
		.message-sent{
			p{
				background:var(--primary);
				font-size:0.875rem;
				color:$white;
			}
		}
	}
	@include respond ('tab-land'){
		height: calc(100vh - 19.8rem);
	}
	@include respond ('phone'){
		height: calc(100vh - 17.8rem);
	}
}
.type-massage{
	padding: 0.625rem;
	border-radius:$radius;
	background:$white;
	textarea{
		border: 0;
		height: 3.4375rem !important;
		resize: none;
		padding: 0.4375rem 0.9375rem;
		background: transparent;
		&:hover,
		&:focus{
			background: transparent;
		}
		@include respond ('phone'){
			height: 2.5rem !important;
			line-height: 1.5;
		}
	}
	.input-group{
		align-items:center;
	}
	&.style-1{
		border: 0;
		margin:0;
		padding: 0.375rem;
		background:rgba(219, 219, 219, 0.25);
		textarea{
			border: 0;
			height: 2.5rem !important;
			resize: none;
			padding: 0.0625rem 0.9375rem;
			background: transparent;
		}
	}
	.btn{
		height:3.125rem;
		width:3.125rem;
		text-align:center;
		padding: 0;
		@include respond ('phone'){
			height:2.5rem;
			width:2.5rem;
		}
	}
	.share-btn{
		height:2.5rem;
		width:2.5rem;
		background:#eee;
		margin-right: 1.0625rem;
		border-radius:3.125rem;
		padding: 0;
	}
	
}

.chat-icon{
	border-bottom:0.0625rem solid #F0F0F0;
	padding-bottom:1.5625rem;
	ul{
		display: flex;
		justify-content: space-around;
		li{
			a{
				height:3rem;
				width:3rem;
				background:#F0F0F0;
				line-height:3rem;
				display:block;
				border-radius:$radius;
				text-align:center;
				&:hover{
					background:var(--primary);
					svg{
						path{
							fill:$white;
						}
					}
				}
			}
		}
	}
}
.share-files{
	border-bottom:0.0625rem solid #F0F0F0;
	padding-bottom: 1.125rem;
	&:last-child{
		border-bottom:0;
	}
	ul{
		li{
			display:flex;
			justify-content:space-between;
			align-items:center;
			padding: 0.5625rem 0;
			cursor: pointer;
			&:first-child{
				padding-top:0;
			}
			h4{
				white-space:nowrap;
			}
			& > div{
				flex:1;
			}
		}
	}
}
.file{
	height:2.5rem;
	width:2.5rem;
	min-width:2.5rem;
	line-height:2.4rem;
	text-align:center;
	background:#F0F0F0;
	display:block;
	border-radius:$radius;
	svg{
		height:1.5rem;
		width:1.5rem;
	}
	
	
}
.share-widget{
	height: calc(100vh - 11.25rem);
    overflow: hidden;
	@include respond ('laptop'){
		box-shadow: 0 0 30px 5px rgba(0,0,0,.05);
	}
	@include respond ('tab-land'){
		height: calc(100vh - 9.25rem);
	}
}

.file-list{
	li{
		& > div{
			width:calc(100% - 3.5rem);
			& > div{
				width:calc(100% - 3rem);
			}
		}
		h4{
			width: 3.5rem;
			text-align:right;
		}
		&:hover{
			.file{
				background:var(--primary);
				svg{
					path{
						fill:$white;
					}
				}
			}
		}
	}
	.fl-info{
		flex:1;
		span{
			font-size:0.8rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
		}
	}
}
.contact-list{
	li{
		width:16%;
		padding: 0 .35rem;
		img{
			width:100%;
			border-radius:$radius;
			@include transitionMedium;
		}
		@include respond ('laptop'){
			padding:0 .15rem;
		}
		&:hover{

				img{
					filter: blur(1px);
				}
		}
	}
	
}
.chat-left-area{
	@include respond ('tab-land'){
		width: 22rem;
	}
	@include respond ('tab-port'){
		width: 21rem;
	}
	@include respond ('phone-land'){
		width: 22rem;
		position: absolute;
		left: -21rem;
		z-index: 1;
		@include transitionFast;
		&.active{
			left:-1.5rem;
		}
	}
}
.chat-right-area{
	.chat-rightarea-btn{
		display:none;
	}
	@include respond ('laptop'){
		width: 23rem;
		position: absolute;
		right: -16.5rem;
		@include transitionFast;
		&.active{
			right: 4.2rem;
		}
		.chat-rightarea-btn{
			position: absolute;
			top: 7rem;
			left: -1.1rem;
			width: 2.2rem;
			height: 3rem;
			background: var(--primary);
			display: inline-flex;
			color: #fff;
			font-size: 1.2rem;
			align-items: center;
			justify-content: center;
			border-radius: .5rem 0 0 .5rem;
			@include transitionFast;
			&.active{
				i{
					transform: rotateY(180deg);
				}
			}
		}
	}
	@include respond ('phone-land'){
		right: -22rem;
		&.active{
			right: -1.5rem;
		}
	}
}
.chat-mid-area{
	@include respond ('laptop'){
		width: auto;
		flex: 1;
	}
	@include respond ('phone-land'){
		.chat-hamburger {
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor:pointer;
			justify-content: center;
			height: 45px;
			width: 45px;
			line-height: 45px;
			text-align: center;
			//background-color: #6418C3;
			color: #fff;
			border-radius: 50%;
			position:relative;
			//z-index: 5;
			span {
				display: block;
				height: 2px;
				margin: 2px auto;
				width: 20px;
				border-radius: 3px;
				background-color: #000;
			}
		}
	}
}