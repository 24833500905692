.review-box{
	padding: 20px 25px;
	border-radius: $radius;
	margin-bottom: 1.875rem;
	
	img{
		height:56px;
		width:56px!important;
		border-radius: $radius;
	}
	p{
		margin:0;
		padding-top: 10px;
	}
	.raiting {
		li{
			i{
				font-size:14px;
			}
		}
	}
}
.courses-chart{
	.book-icon{
		height:56px;
		width:56px;
		line-height:56px;
		text-align:center;
		display:block;
		border-radius:$radius;
	}
	padding: 13px 0;
}